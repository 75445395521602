import { createStore } from 'vuex'

const storeFilePond = {
  state: {
    _adjunto: {}
  },
  getters: {
    adjunto: state => state._adjunto
  },
  mutations: {
    setAdjunto (state, payload) {
      // console.log('esta es la data que llega', payload)
      state._adjunto = {
        ...payload
      }
    }
  },
  actions: {
    uploadFile ({ commit }, payload) {
      commit('setAdjunto', payload)
    }
  }
}
const store = createStore(storeFilePond)
export default {
  namespaced: true,
  ...store
}
