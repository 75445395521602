<template>
  <section>
    <div class="w-full bg-white rounded-md mt-2 p-4">
      <div class="w-full flex gap-2 items-center">
        <i class="pi pi-list"></i>
        <p class="text-lg font-bold">{{ route.params.id ? 'Actualizar' : 'Crear' }} Artículo</p>
      </div>
    </div>
    <div class="grid grid-cols-2 gap-10 my-5 mx-5">
      <div class="grid grid-cols-3 gap-5 mx-5">
        <!-- <div class="flex flex-col">
          <span class="font-bold">Imagen MD </span>
          <div class="flex gap-2 justify-center">
            <FileUpload mode="basic" class="w-full" ref="imgMedicamento" name="demo[]" url="./upload.php" accept="image/*" :maxFileSize="1000000" @change="handleChangePdf" />
          </div>
        </div> -->
        <div class="flex flex-col col-start-2 col-span-1">
          <span class="font-bold">Imagen MD </span>
          <div
              class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative"
            >
              <div class="w-20 h-20 sm:w-24 sm:h-24 flex-none lg:w-32 lg:h-32 image-fit relative border-2" v-if="imagen">
                <img
                  ref="imagenBase64"
                  alt="Midone Tailwind HTML Admin Template"
                  class=" w-full h-full m-auto"
                  :src='imagen'
                />
              </div>
              <div class="w-20 h-20 sm:w-24 sm:h-24 flex justify-center items-center lg:w-32 lg:h-32 image-fit relative border-blue-600 rounded-full border-2 " v-else>
                <img
                  alt="Midone Tailwind HTML Admin Template m-auto"
                  class="rounded-full "
                  style="width: 90%;height: 90%;"
                  src='@/assets/images/icon-box.png'
                />
              </div>
              <button
                @click="displayMaximizable = true"
                class="absolute mb-1 mr-1 flex items-center justify-center bottom-0 right-0 bg-blue-600 rounded-full p-2"
              >
                <i class="pi pi-pencil w-4 h-4 text-white"></i>
              </button>
              <Dialog header="Agregar imagen" v-model:visible="displayMaximizable" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '25vw'}" :modal="true">
                <div>
                  <div class="my-2">
                    <div class="mt-2">
                      <FilePond ref="pond"/>
                    </div>
                  </div>
                </div>
                <template class="text-center" #footer>
                  <Button label="Guardar" class="w-full" @click="closeMaximizable()" autofocus />
                </template>
              </Dialog>
            </div>
          <!-- <button class="w-full">
            <FilePond ref="pond" @captureFile="onCaptureFile" />
          </button> -->
          <!-- <span v-if="!adjunto" class="text-xs text-red-600 ">El archivo es requerido</span> -->
        </div>
        <div class="flex flex-col col-start-1">
          <span class="font-bold">Código </span>
          <InputText class="rounded w-full border uppercase" :disabled="route.params.id" type="text" v-model="model.ItemCode" placeholder="MD123456" />
          <MessageError :text="errors.ItemCode"/>
        </div>
        <div class="flex flex-col col-span-2">
          <span class="font-bold">Nombre MD </span>
          <InputText class="rounded w-full border uppercase" type="text" v-model="model.ItemName" placeholder="Nombre (Princ Act) - presentación - Laboratorio" />
          <MessageError :text="errors.ItemName"/>
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Cod. Barras </span>
          <InputText class="rounded w-full border" type="text" v-model="model.CodeBars" />
          <!-- <MessageError :text="errors.CodeBars"/> -->
        </div>
        <div class="flex flex-col col-span-2">
          <span class="font-bold">Comentario (Desc. Presentación Comercial)</span>
          <InputText class="rounded w-full border uppercase" type="text" v-model="model.UserText" placeholder="" />
          <!-- <textarea v-model="model.UserText" class="rounded w-full border"></textarea> -->
          <!-- <MessageError :text="errors.UserText"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Concentración </span>
          <InputText class="rounded w-full border uppercase" type="text" v-model="model.U_PHR_Concentracion" />
          <!-- <MessageError :text="errors.U_PHR_Concentracion"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Consecutivo Expediente</span>
          <InputText class="rounded w-full border uppercase" type="text" v-model="model.U_PHR_ConsExped"/>
          <MessageError :text="errors.U_PHR_ConsExped"/>
        </div>
        <div class="flex flex-col">
          <span class="font-bold">CUM </span>
          <InputText class="rounded w-full border" type="text" v-model="model.U_PHR_Exp_CUM" placeholder="0000000-00" />
          <MessageError :text="errors.U_PHR_Exp_CUM"/>
        </div>
        <div class="flex flex-col mt-auto">
          <span class="font-bold">Expediente CUM </span>
          <InputText class="rounded w-full border uppercase" type="number" v-model="model.SWW" />
          <MessageError :text="errors.SWW"/>
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Cant. CUM (Ud. Present. Comercial) </span>
          <InputText class="rounded w-full border " type="number" v-model="model.U_PHR_CantCUM" />
          <MessageError :text="errors.U_PHR_CantCUM"/>
        </div>
        <div class="flex flex-col mt-auto">
          <span class="font-bold">Reg. Sanitario </span>
          <InputText class="rounded w-full border uppercase" type="text" v-model="model.U_PHR_RegSanitario" />
          <MessageError :text="errors.U_PHR_RegSanitario"/>
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Fecha Expedición Reg. </span>
          <InputText class="rounded w-full border" type="date" v-model="model.U_PHR_FecExpedi" />
          <MessageError :text="errors.U_PHR_FecExpedi"/>
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Fecha Vencimiento Reg.</span>
          <InputText class="rounded w-full border" type="date" v-model="model.U_PHR_FecVenci"/>
          <MessageError :text="errors.U_PHR_FecVenci"/>
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Est. Registro Sanitario</span>
          <Dropdown v-model="model.U_PHR_EstRegistro" :options="EstReg" optionLabel="Value" optionValue="Codigo"
                  placeholder="Seleccione un estado" class="rounded"/>
          <!-- <MessageError :text="errors.U_PHR_EstRegistro"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Principio Activo</span>
          <InputText class="rounded w-full border uppercase" type="text" v-model="model.U_PHR_PrincActivo" placeholder="" />
          <!-- <MessageError :text="errors.U_PHR_PrincActivo"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Cant. Principio Activo</span>
          <InputText class="rounded w-full border" type="text" v-model="model.U_PHR_CantPrincAct" placeholder="" />
          <!-- <MessageError :text="errors.U_PHR_CantPrincAct"/> -->
        </div>
      </div>
      <div class="grid grid-cols-3 gap-5 mx-5">
        <div class="flex flex-col">
          <span class="font-bold">Unidad medida inv.</span>
          <InputText class="rounded w-full border uppercase" type="text" v-model="model.SalUnitMsr" placeholder="" />
          <!-- <MessageError :text="errors.U_PHR_UndMedida"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Unidad Princ. Activo</span>
          <InputText class="rounded w-full border uppercase" type="text" v-model="model.U_PHR_UndMedida" placeholder="" />
          <!-- <MessageError :text="errors.U_PHR_UndMedida"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Unidad Referencia</span>
          <InputText class="rounded w-full border uppercase" type="text" v-model="model.U_PHR_UdREF" placeholder="" />
          <!-- <MessageError :text="errors.U_PHR_UdREF"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Clasificación MX</span>
          <Dropdown v-model="model.U_PHR_ClaMx" :options="ClaMx" optionLabel="Value" optionValue="Codigo"
                  placeholder="Seleccione una opción"/>
          <!-- <MessageError :text="errors.U_PHR_ClaMx"/> -->
        </div>
        <div class="flex flex-col col-span-2">
          <span class="font-bold">Nombre Compras </span>
          <InputText class="rounded w-full border uppercase" type="text" v-model="model.U_PHR_ItemName" placeholder="Nombre (Princ Act) - presentación - Laboratorio" />
          <!-- <MessageError :text="errors.U_PHR_ItemName"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Tipo de Artículo</span>
          <Dropdown v-model="model.U_PHR_TpArt" :options="tiposArt" optionLabel="label" optionValue="label"
                  placeholder="Seleccione un tipo"/>
          <!-- <MessageError :text="errors.U_PHR_TpArt"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Laboratorio</span>
          <!-- <Dropdown v-model="model.U_PHR_Lab" :options="laboratorios" optionLabel="Name" optionValue="Value"
                  placeholder="Seleccione un laboratorio"/> -->
          <AutoComplete v-model="model.U_PHR_Lab" dropdown :suggestions="laboratorios" @complete="searchLab">
            <template #option="slotProps">
                <div class="flex align-options-center">
                    <div>{{ slotProps.option.Name }}</div>
                </div>
              </template>
          </AutoComplete>
          <!-- <MessageError :text="errors.U_PHR_Lab"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Nivel</span>
          <Dropdown v-model="model.U_PHR_Nivel" :options="niveles" optionLabel="Value" optionValue="Codigo"
                  placeholder="Seleccione un nivel"/>
          <!-- <MessageError :text="errors.U_PHR_Nivel"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Indicador de Impuesto</span>
          <Dropdown v-model="model.ItmsGrpCod" :options="indImpuesto" optionLabel="Value" optionValue="Codigo"
                  placeholder="Seleccione un indicador"/>
          <!-- <MessageError :text="errors.ItmsGrpCod"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Forma Farmacéutica</span>
          <!-- <Dropdown v-model="model.U_PHR_FormFarma" :options="formFarma" optionLabel="Name" optionValue="Value"
                  placeholder="Seleccione una opción"/> -->
          <AutoComplete v-model="model.U_PHR_FormFarma" dropdown :suggestions="formFarma" @complete="searchFor">
            <template #option="slotProps">
                <div class="flex align-options-center">
                    <div>{{ slotProps.option.Name }}</div>
                </div>
              </template>
          </AutoComplete>
          <!-- <MessageError :text="errors.U_PHR_FormFarma"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Vía de Administración</span>
          <Dropdown v-model="model.U_PHR_ViaAdmon" :options="viaAdmon" optionLabel="Codigo" optionValue="Value"
                  placeholder="Seleccione una opción"/>
          <!-- <MessageError :text="errors.U_PHR_ViaAdmon"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Clase de Riesgo</span>
          <Dropdown v-model="model.U_PHR_Riesgo" :options="riesgos" optionLabel="Codigo" optionValue="Codigo"
                  placeholder="Seleccione una clase"/>
          <!-- <MessageError :text="errors.U_PHR_Riesgo"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Temperatura de Almacenamiento</span>
          <Dropdown v-model="model.U_PHR_TemAlmacenar" :options="temperaturas" optionLabel="Codigo" optionValue="Value"
                  placeholder="Seleccione una temp"/>
          <!-- <MessageError :text="errors.U_PHR_TemAlmacenar"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Grupo Terapéutico</span>
          <!-- <Dropdown v-model="model.U_PHR_GrTerap" :options="GrTerap" optionLabel="Name" optionValue="Value"
                  placeholder="Seleccione un grupo"/> -->
          <AutoComplete v-model="model.U_PHR_GrTerap" dropdown :suggestions="GrTerap" @complete="searchGrp">
            <template #option="slotProps">
                <div class="flex align-options-center">
                    <div>{{ slotProps.option.Name }}</div>
                </div>
              </template>
          </AutoComplete>
          <!-- <MessageError :text="errors.U_PHR_GrTerap"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Tipo (Comercial / Genérico)</span>
          <Dropdown v-model="model.U_PHR_Tipo" :options="tipoMX" optionLabel="Value" optionValue="Codigo"
                  placeholder="Seleccione un tipo"/>
          <!-- <MessageError :text="errors.U_PHR_Tipo"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold">ATC</span>
          <!-- <Dropdown v-model="model.U_PHR_NumATC" :options="NumATC" optionLabel="Codigo" optionValue="Value"
                  placeholder="Seleccione una opción"/> -->
          <AutoComplete v-model="model.U_PHR_NumATC" optionLabel="U_PHR_DscATC" dropdown :suggestions="NumATC" @complete="searchAtc">
            <template #option="slotProps">
                <div class="flex align-options-center">
                    <div>{{ slotProps.option.U_PHR_CdATC }} - {{ slotProps.option.U_PHR_DscATC }}</div>
                </div>
              </template>
          </AutoComplete>
          <!-- <MessageError :text="errors.U_PHR_NumATC"/> -->
        </div>
        <div class="flex flex-col col-span-2">
          <span class="font-bold">Descripcion ATC</span>
          <InputText class="rounded w-full border uppercase" type="text" v-model="model.U_PHR_DescATC" placeholder="" />
          <!-- <MessageError :text="errors.U_PHR_DescATC"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Norma Regulación</span>
          <Dropdown v-model="model.U_PHR_RegActoAdmon" :options="normaReg" optionLabel="Name" optionValue="Name"
                  placeholder="Seleccione una norma"/>
          <!-- <MessageError :text="errors.U_PHR_RegActoAdmon"/> -->
        </div>
        <div class="flex flex-col">
          <span class="font-bold">Tipo Regulación</span>
          <Dropdown v-model="model.U_PHR_TpReg" :options="tipoReg" optionLabel="Value" optionValue="Codigo"
                  placeholder="Seleccione un tipo"/>
          <!-- <MessageError :text="errors.U_PHR_TpReg"/> -->
        </div>
      </div>
    </div>
    <div class="w-full flex justify-end mt-5 ">
      <Button label="Limpiar"
              class="bg-blue-900 p-button-outlined"
              @click="resetFilters"
      />
      <Button label="Guardar"
              class="bg-blue-900 mx-5 px-5"
              type="button"
              @click="onSubmit"
      />
    </div>
  </section>
</template>
<script setup>
import { ref, onMounted, watch, computed } from 'vue'
import { useField, useForm } from 'vee-validate'
import { useRoute } from 'vue-router'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import dayjs from 'dayjs'
import ArticulosService from '../../../../../services/medicamentos.service'
import FilePondStore from '../filepond.store'
import FilePond from './filePound.vue'

// instancias
const route = useRoute()
const _ArticulosService = ref(new ArticulosService())
// referencias
const imgMedicamento = ref()
const EstReg = ref(null)
const ClaMx = ref(null)
const tiposArt = ref(null)
const viaAdmon = ref(null)
const laboratorios = ref(null)
const formFarma = ref([])
const niveles = ref(null)
const indImpuesto = ref(null)
const riesgos = ref(null)
const temperaturas = ref(null)
const GrTerap = ref(null)
const tipoMX = ref(null)
const NumATC = ref(null)
const normaReg = ref(null)
const tipoReg = ref(null)
const imagenBase64 = ref()
const pond = ref()
const imagen = ref('')
const displayMaximizable = ref(false)
const adjuntos = ref([])
const adjunto = computed(
  () => FilePondStore.getters.adjunto
)
// const back = process.env.VUE_APP_MICROSERVICE_API_CONFIG_OV.split('/api')[0]
const filterValue = ref(null)

const validationSchema = yup.object({
  ItemCode: yup.string().required('El código es requerido').label(''),
  ItemName: yup.string().required('La descripción es requerida').label(''),
  SWW: yup.string().required('El CUM es requerido').label(''),
  U_PHR_ConsExped: yup.string().max(5).required('El Consecutivo es requerido').label(''),
  U_PHR_Exp_CUM: yup.string().max(8).required('El Exp CUM es requerido').label(''),
  U_PHR_RegSanitario: yup.string().required('El Registro sanitario es requerida').label(''),
  U_PHR_FecExpedi: yup.date().required('la fecha de expedición es requerida').label(''),
  U_PHR_FecVenci: yup.date().required('La fecha de vencimiento es requerida').label('')
})

const { errors, handleSubmit, values: model, handleReset } = useForm({ validationSchema })

  useField('ItemCode', null, { initialValue: '' })
  useField('ItemName', null, { initialValue: '' })
  useField('SWW', null, { initialValue: '' })
  useField('CodeBars', null, { initialValue: '' })
  useField('U_PHR_ConsExped', null, { initialValue: '' })
  useField('U_PHR_Exp_CUM', null, { initialValue: null })
  useField('U_PHR_RegSanitario', null, { initialValue: '' })
  useField('U_PHR_FecExpedi', null, { initialValue: '' })
  useField('U_PHR_FecVenci', null, { initialValue: '' })
  useField('PicturName', null, { initialValue: '' })

const onSubmit = handleSubmit(async (values) => {
    if (!values) throw new Error('Error de validación')
    // model.PicturName = await helper.base64String(adjunto.value.urlPath)
    for (const [index, item] of Object.entries(model)) {
      if (typeof item === 'string' && index !== 'PicturName') {
        model[index] = item.toUpperCase()
      }
    }
    if (route.params.id) {
      _ArticulosService.value.editarArticulo(model).then((res) => {
        if (res.data.error) {
          console.error('Error al actualizar el medicamento, por favor intente nuevamente')
        } else {
          handleReset()
          Swal.fire({
            icon: 'success',
            title: 'Artículo Actualizado',
            text: 'El artículo se ha actualizado correctamente'
          })
        }
      })
    } else {
      _ArticulosService.value.crearArticulo(model).then((res) => {
        if (res.data.error) {
          console.error('Error al crear el medicamento, por favor intente nuevamente')
        } else {
          // toast.add({ severity: 'success', summary: 'Exito', detail: 'Medicamento creado exitosamente', life: 3000 })
          Swal.fire({
            icon: 'success',
            title: 'Artículo creado',
            text: 'El artículo se ha creado correctamente'
          })
        }
        handleReset()
      })
    }
})
const resetFilters = () => {
  model.value = {
    ItemCode: '',
    ItemName: '',
    SWW: '',
    CodeBars: '',
    U_PHR_RegSanitario: '',
    U_PHR_FecExpedi: '',
    U_PHR_FecVenci: '',
    PicturName: ''
  }
  imgMedicamento.value.files[0] = null
}
const closeMaximizable = async () => {
  // console.log('lo que hay', model)
  pond.value.onSubmitSupport().then(() => {
    model.PicturName = adjunto.value.urlPath
    imagen.value = 'data:image/jpg;base64,' + adjunto.value.urlPath
    adjuntos.value.push({
      urlPath: adjunto.value.urlPath
    })
    displayMaximizable.value = false
  })
}
const searchLab = (event) => {
  if (event.query) {
      _ArticulosService.value.getLaboratorios(event.query).then(({ data }) => {
        laboratorios.value = data.map(m => m.Name)
      })
    } else {
      laboratorios.value = laboratorios.value.map(m => m.Name)
    }
}

const searchFor = (event) => {
  if (event.query) {
      _ArticulosService.value.getFormFarma(event.query).then(({ data }) => {
        formFarma.value = data.map(m => m.Name)
      })
    } else {
      formFarma.value = formFarma.value.map(m => m.Name)
    }
}

const searchGrp = (event) => {
  if (event.query) {
      _ArticulosService.value.getGrTerap(event.query).then(({ data }) => {
        GrTerap.value = data.map(m => m.Name)
      })
    } else {
      GrTerap.value = GrTerap.value.map(m => m.Name)
    }
}

const searchAtc = (event) => {
  if (event.query) {
      _ArticulosService.value.getNumATC(event.query).then(({ data }) => {
        // NumATC.value = data.map(m => { return { U_PHR_CdATC: m.U_PHR_CdATC, U_PHR_DscATC: m.U_PHR_DscATC } })
        NumATC.value = data.map(m => m.U_PHR_CdATC)
      })
    } else {
      // NumATC.value = NumATC.value.map(m => { return { U_PHR_CdATC: m.U_PHR_CdATC, U_PHR_DscATC: m.U_PHR_DscATC } })
      NumATC.value = NumATC.value.map(m => m.U_PHR_CdATC)
    }
}

watch(() => filterValue.value, (event) => {
  console.log('watch--', event)
})

onMounted(() => {
  if (route.params.id) {
    _ArticulosService.value.getArticulo(route.params.id).then(async ({ data }) => {
      for (const [index, item] of Object.entries(data)) {
        model[index] = item
      }
      model.U_PHR_FecExpedi = dayjs(model.U_PHR_FecExpedi).format('YYYY-MM-DD')
      model.U_PHR_FecVenci = dayjs(model.U_PHR_FecVenci).format('YYYY-MM-DD')
      imagen.value = 'data:image/jpg;base64,' + data.PicturName
  })
  }
  _ArticulosService.value.getEstReg('ESTREG').then(({ data }) => {
    EstReg.value = data
  })
  _ArticulosService.value.getClaMx('CLASMX').then(({ data }) => {
    ClaMx.value = data
  })
  _ArticulosService.value.getTiposArt('CLASMX').then(({ data }) => {
    tiposArt.value = data
  })
  _ArticulosService.value.getViaAdmon('VIAADMIN').then(({ data }) => {
    viaAdmon.value = data
  })
  _ArticulosService.value.getLaboratorios().then(({ data }) => {
    laboratorios.value = data
  })
  _ArticulosService.value.getFormFarma().then(({ data }) => {
    formFarma.value = data
  })
  _ArticulosService.value.getNiveles('NIVEL').then(({ data }) => {
    niveles.value = data
  })
  _ArticulosService.value.getIndImpuesto().then(({ data }) => {
    indImpuesto.value = data
  })
  _ArticulosService.value.getRiesgos().then(({ data }) => {
    riesgos.value = data
  })
  _ArticulosService.value.getTemperaturas().then(({ data }) => {
    temperaturas.value = data
  })
  _ArticulosService.value.getGrTerap().then(({ data }) => {
    GrTerap.value = data
  })
  _ArticulosService.value.getTipoMX('COMPRASTIPO').then(({ data }) => {
    tipoMX.value = data
  })
  _ArticulosService.value.getNumATC().then(({ data }) => {
    NumATC.value = data
  })
  _ArticulosService.value.getNormaReg().then(({ data }) => {
    normaReg.value = data
  })
  _ArticulosService.value.getTipoReg('TPREG').then(({ data }) => {
    tipoReg.value = data
  })
})
</script>
<style>
</style>
